import React from "react";
import { ToastContainer, toast } from "react-toastify";
import { Close as CloseIcon } from "@material-ui/icons";
import Notification from "./Notification"
import useStyles from "./styles";


export function DefaultNotifcation(props) {
    const classes = useStyles();
    const defaultProps = {
        type: "info",
        variant: "contained",
        color: "primary",
        ...props
    };
    return <Notification {...defaultProps} className={classes.notificationComponent} />;
}

export function sendNotification(componentProps, options) {
    options = {
        type: "info",
        position: toast.POSITION.TOP_RIGHT,
        ...options
    };
    return toast(<DefaultNotifcation {...componentProps} />, options);
}

export function NotificationContainer(props) {
    const classes = useStyles();
    const CloseButton = ({ closeToast, className }) =>
        props.closeButton || <CloseIcon className={className || classes.notificationCloseButton} onClose={closeToast} />;
    return <ToastContainer
        className={classes.toastsContainer}
        closeButton={<CloseButton />}
        closeOnClick={props.closeOnClick || true}
        progressClassName={props.progressClassName || classes.progress}
    />
}

    // <CloseIcon className={classes.notificationCloseButton} onClick={closeToast} />