import React from "react";
import _ from "lodash";
import moment from "moment";
import http from "../package_cache/http";
import { kRiderClassNames } from "../constants"
import { useUserState } from "./UserContext";
import { serverAddress } from "../constants";

const currentFleetState = {};
const FleetContext = React.createContext();
const VehicleContext = React.createContext({ vehicleIds: [] });

const baseURL = serverAddress;

function noOp() {}

const initialState = {
    fleet: [],
    http,
    fleetId: localStorage.getItem("config.fleetId") || "autox-cn-shenzhen",
    initialized: false,
    rider: {
        riderId: window.localStorage.getItem("config.riderId") || false,
        riderTitle: window.localStorage.getItem("config.riderTitle") || false
    },
    vehicles: [],
    loadVehicles: noOp
};
/**
 * Transforms the state based on action and returns a new state.
 *
 * @param {any} state
 * @param {any} action
 */
function fleetReducer(state, action) {
    switch (action.type) {
        case "UPDATE_RIDER": {
            return { ...state, ...action };
        }
        case "LOADING_FLEETS": {
            return { ...state, ...action };
        }
        case "LOAD_FLEETS": {
            const { fleet, user, token } = action.data;
            const currentFleet = _.find(fleet, f => f.id === state.fleetId);
            return {
                ...state,
                type: action.type,
                fleet,
                currentFleet,
                user,
                token,
                initialized: true
            };
        }
        case "SET_VEHICLES": {
            currentFleetState.vehicleIds = action.vehicleIds;
            return { ...state, vehicleIds: action.vehicleIds };
        }
        case "SET_USER": {
            const { user } = action.data;
            return { ...state, user };
        }
        case "SET_FLEET": {
            let { fleetId, user } = action.data;
            user = user || state.user;

            let currentFleet = _.find(state.fleet, f => f.id === fleetId) || _.head(state.fleet);
            if (!currentFleet) {
                return state;
            }

            fleetId = currentFleet.id;

            let http = state.http;
            window.localStorage.setItem("config.fleetId", fleetId);

            return {
                ...state,
                type: action.type,
                http,
                fleetId,
                currentFleet,
                user
            };
        }

        case "SET_LOAD_VEHICLES": {
            return {
                ...state,
                ...action
            };
        }

        case "RESET_LOAD_VEHICLES": {
            return {
                ...state,
                loadVehicles: noOp
            };
        }

        default: {
            throw new Error(`Unhandled action type: ${action.type}`);
        }
    }
}

function vehicleReducer(state, action) {
    const newState = { ...state, ...action };
    return newState;
}

export function FleetProvider({ children }) {
    const fleetContext = React.useReducer(fleetReducer, initialState);
    return <FleetContext.Provider value={fleetContext}>{children}</FleetContext.Provider>;
}

export function VehicleProvider({ children }) {
    const vehicleContext = React.useReducer(vehicleReducer, { vehicles: [] });
    return <VehicleContext.Provider value={vehicleContext}>{children}</VehicleContext.Provider>;
}

/**
 * Returns the state of the fleet context.
 */
export function useFleetContext() {
    var context = React.useContext(FleetContext);
    if (context === undefined) {
        throw new Error("useFleetState must be used within a FleetProvider");
    }
    return context;
}

export function useVehicleContext() {
    var context = React.useContext(VehicleContext);
    if (context === undefined) {
        throw new Error("useVehicleContext must be used within a VehicleProvider");
    }
    return context;
}

export function FleetControl() {
    const [state, dispatch] = useFleetContext();
    const userState = useUserState();

    React.useEffect(() => {
        if ((!state.type || state.type === "LOADING_FLEETS") && userState.user) {
            state.http.post("/admin/v1/GetFleets", {}).then(data => {
                dispatch({ type: "LOAD_FLEETS", data });
                dispatch({
                    type: "SET_FLEET",
                    data: {
                        fleetId: localStorage.getItem("config.fleetId"),
                        user: userState.user
                    }
                });
            });
        }
    }, [state.type, userState.user, dispatch, state.http]);

    React.useEffect(() => {
        console.log(`FleetID [${state.fleetId}] is changed; Switch HTTP Client`);
        dispatch({ type: "SET_USER", data: { user: userState.user } });
    }, [state.fleetId]);
    return <></>;
}

function createXTaxiClient(fleetState, dispatch, setXTaxiClient) {
    // const userId = fleetState.user.
    // const xtaxiClient = new XTaxiBrowserClient();
    // console.log(fleetState.user);
}

function createVehicleMonitor(fleetState, dispatch, setMonitor, fleetDispatch) {
    const eventSource = new EventSource(
        `${baseURL}/admin/v1/stream/fleet-status/${fleetState.fleetId}`
    );
    eventSource.onerror = () => {
        eventSource.close();
        setMonitor(null);

        // Try 2 seconds later
        setTimeout(() => {
            setMonitor(createVehicleMonitor(fleetState, dispatch, setMonitor, fleetDispatch));
        }, 2000);
    };
    eventSource.onmessage = function (event) {
        const data = _.map(_.get(JSON.parse(event.data), "vehicles", []), v => {
            v.state = v.state || {};
            const lat = v.state.position?.latitude?.toFixed(6) ?? "?";
            const lng = v.state.position?.longitude?.toFixed(6) ?? "?";
            return {
                id: v.id,
                driverReady: (v.driverReady && "YES") || "NO",
                clientReady: (v.state.readiness && "YES") || "NO",
                riderClass: _.join(
                    _.map(v.definition.riderClass, x => kRiderClassNames[x]),
                    ","
                ),
                currentTripId: v.currentTripId || "-",
                currentStep: v.currentStep || "-",
                position: `${lat},\t${lng}`,
                acceptOrderTypes: v.definition?.acceptOrderTypes?.join(",") ?? "",
                acceptVendors: v.definition?.acceptVendors?.join(",") ?? "",
                numConnections: v.connections?.length || 0,
                updatedAt: moment(v.updatedAt).toLocaleString(),
                _source: v
            };
        });
        const action = {
            type: "UPDATE_VEHICLE",
            vehicles: data,
            vehicleIds: _.sortedUniq(_.sortBy(_.map(data, "id")))
        };

        if (!_.isEqual(currentFleetState.vehicleIds, action.vehicleIds)) {
            fleetDispatch({ type: "SET_VEHICLES", vehicleIds: action.vehicleIds });
        }
        dispatch(action);
    };
    return eventSource;
}

export function VehicleControl() {
    const [fleetState, dispatchFleetContext] = useFleetContext();
    const [vehicleState, dispatchVehicleContext] = useVehicleContext();
    const [monitor, setMonitor] = React.useState(null);
    const [xtaxiClient, setXTaxiClient] = React.useState(null);
    const { isAuthenticated } = useUserState()

    const disposeMonitor = () => {
        monitor && monitor.close();
        dispatchVehicleContext({ ...vehicleState, selectedIndex: -1, selectedVehicle: false });
    };
    React.useEffect(() => {
        disposeMonitor();
        if (fleetState.fleetId && isAuthenticated) {
            setMonitor(
                createVehicleMonitor(
                    fleetState,
                    dispatchVehicleContext,
                    setMonitor,
                    dispatchFleetContext
                )
            );
            setXTaxiClient(createXTaxiClient(fleetState, dispatchVehicleContext, setXTaxiClient));
            return disposeMonitor;
        }
    }, [fleetState.fleetId, fleetState.user, isAuthenticated]);
    return null;
}
