import React from "react";

export const AMAP_VENDOR = "amap";
export const AUTOX_VENDOR = "autox";
export const DUMMY_VENDOR = 'dummy'
export const SAIC_VENDOR = 'saic'
export const TRGS_VENDOR = "trgs"
export const SHMAAS_VENDOR = "shmaas"

const VendorContext = React.createContext({
    vendor: AUTOX_VENDOR
});

function VendorReducer(state, action) {
    switch (action.type) {
        case "SWITCH_VENDOR":
            return {
                ...state,
                vendor: action.payload
            };
        default:
            throw new Error(`Unknown action type: ${action.type}`);
    }
}

export function VendorProvider({ children }) {
    const [state, dispatch] = React.useReducer(VendorReducer, { vendor: AUTOX_VENDOR });
    return (
        <VendorContext.Provider value={Object.assign(state, { dispatch })}>
            {children}
        </VendorContext.Provider>
    );
}

export function useVendorContext() {
    const context = React.useContext(VendorContext);
    if (context === undefined) {
        throw new Error("useVendorContext must be used within a VendorProvider");
    }
    return context;
}
