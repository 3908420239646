import React, { useEffect } from "react";
import { HashRouter, Route, Switch, Redirect } from "react-router-dom";
import {
  FleetControl,
  VehicleProvider,
  VehicleControl,
} from "../context/FleetContext";
import { NotificationContainer } from "../components/Notification/NotificationContainer";
import { CircularProgress } from "@material-ui/core";
import http from "../package_cache/http"
// pages
import Error from "../pages/error";
import Login from "../pages/login";

// context
import { useUserState, useUserDispatch } from "../context/UserContext";
import { Suspense } from "react";

const isDevelop = process.env.NODE_ENV === "development";

export default function App() {
  // global
  var { isAuthenticated, user } = useUserState();

  return (
    <Suspense fallback={<CircularProgress size={20} style={{ marginTop: "14px" }} />}>
      <HashRouter>
        <Switch>
          <Route exact path="/" render={() => <Redirect to="/app/trips" />} />
          <PublicRoute path="/login" component={Login} />
          <Route
            exact
            path="/app"
            render={() => <Redirect to="/app/trips" />}
          />
          <VehicleProvider>
            <VehicleControl />
            <PrivateRoute path="/app" component={React.lazy(() => import('./Layout'))} />
          </VehicleProvider>
          <Route component={Error} />
        </Switch>
      </HashRouter>
      <NotificationContainer />
      <FleetControl />
    </Suspense>
      
      
  );

  // #######################################################################

  /**
   * If the user is not authenticated, go to login
   */
  function PrivateRoute({ component, ...rest }) {
    const { isAuthenticated, user } = useUserState()
    const userDispatch = useUserDispatch()

    const render = props => {
      const redirectTo = {
        pathname: "/login",
        state: { from: props.location },
      };
      return isAuthenticated ? (
        React.createElement(component, props)
      ) : (
        <Redirect to={redirectTo} />
      );
    };

    useEffect(() => {
      if (user === undefined) {
        http.get("/admin/v1/CurrentUserInfo").then((data) => {
          if (data) {
            userDispatch({ type: "LOGIN_SUCCESS", user: { email: data.mail, name: data.mail } })
          } else {
            userDispatch({ type: "SIGN_OUT_SUCCESS" })
            window.location.hash = '#/login'
          }
        }).catch(err => {
          if(isDevelop === true) {
            userDispatch({ type: "LOGIN_SUCCESS", user: { email: "jiancui@autox.clu", name: "jiancui@autox.clu" } })
            return;
          }
          userDispatch({ type: "SIGN_OUT_SUCCESS" })
          window.location.hash = '#/login'
        })
      }
    }, [user])

    if (user === undefined) return null

    return <Route {...rest} render={render} />;
  }

  /**
   * If the user is not authenticated, show <Login/>;
   * otherwise go to "/"
   */
  function PublicRoute({ component, ...rest }) {
    const render = props => {
      // if (isAuthenticated) {
      //   return <Redirect to={{ pathname: "/" }} />;
      // }
      return React.createElement(component, props);
    };
    return <Route {...rest} render={render} />;
  }
}
