import React from "react";
import _ from "lodash";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
// import DialogActions from "@material-ui/core/DialogActions";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

const styles = theme => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: "absolute",
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

// Create a context with values
const DialgoContext = React.createContext({ open: false, json: {} });

// Modify state based on action
function dialogReducer(state, action) {
    switch (action.type) {
        case "OPEN":
            return {
                ...state,
                open: true,
                title: action.title,
                json: action.json,
                text: action.text,
            };
        case "CLOSE":
            return { open: false, json: {} };
        case "DATE_PICKER_OPEN":
            return { type: "CUSTOM_DIALOG_OPEN", title: action.title, content: action.content };
        default: {
            return { ...action };
        }
    }
}

export function DialogProvider({ children }) {
    const reducer = React.useReducer(dialogReducer, { open: false, json: {} });
    return <DialgoContext.Provider value={reducer}>{children}</DialgoContext.Provider>;
}

/**
 * The vehicle context stores the reducer.
 * Returns `[state, dispatch]` pair to access the context state.
 */
export function useDialogContext() {
    return React.useContext(DialgoContext);
}

export function JsonDialogImpl(props) {
    const { classes } = props;
    const [state, dispatch] = useDialogContext();

    const handleClose = () => {
        dispatch({ type: "CLOSE" });
    };

    return (
        <Dialog
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={state.open || false}
        >
            <DialogTitle disableTypography className={classes.root}>
                <Typography variant="h6">{state.title || "JSON View"}</Typography>
                <IconButton
                    aria-label="close"
                    className={classes.closeButton}
                    onClick={handleClose}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>

            <DialogContent dividers>
                <pre>
                    {(state.json && JSON.stringify(state.json, null, 2)) || state.text || "EMPTY"}
                </pre>
            </DialogContent>
            {/* <DialogActions>
                <Button autoFocus onClick={handleClose} color="primary">
                    Close
                </Button>
            </DialogActions> */}
        </Dialog>
    );
}

/**
 *
 * state.title: string;
 * state.select: item => boolean;
 * state.items: { id: string, value: string }[]
 *
 * @param {any} props
 */
export function SelectorDialogImpl(props) {
    const { classes } = props;
    const [state, dispatch] = useDialogContext();

    const handleClose = () => {
        dispatch({});
    };

    return (
        <Dialog
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={state.type === "SELECTOR_OPEN" || false}
        >
            <DialogTitle disableTypography className={classes.root}>
                <Typography variant="h6">{state.title || "SELECT FROM BELOW"}</Typography>
                <IconButton
                    aria-label="close"
                    className={classes.closeButton}
                    onClick={handleClose}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>

            <DialogContent dividers>
                <List>
                    {_.map(state?.items, (item, idx) => (
                        <ListItem
                            button
                            onClick={() => {
                                if (state.select) {
                                    Promise.resolve(state.select(item)).then(() => {
                                        handleClose();
                                    });
                                }
                            }}
                            key={item.id || `selector-${idx}`}
                        >
                            <ListItemText primary={item.value} />
                        </ListItem>
                    ))}
                </List>
            </DialogContent>
        </Dialog>
    );
}

/**
 * Fired by dispatch({type: "CUSTOM_DIALOG_OPEN", title, content })
 *
 * state.title: string;
 * state.content: Component
 *
 * @param {any} props
 */
export function CustomDialogImpl(props) {
    const { classes } = props;
    const [state, dispatch] = useDialogContext();

    const handleClose = () => {
        dispatch({});
    };

    return (
        <Dialog
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={state.type === "CUSTOM_DIALOG_OPEN" || false}
        >
            <DialogTitle disableTypography className={classes.root}>
                <Typography variant="h6">{state.title}</Typography>
                <IconButton
                    aria-label="close"
                    className={classes.closeButton}
                    onClick={handleClose}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent dividers>{state.content}</DialogContent>
        </Dialog>
    );
}

export const JsonDialog = withStyles(styles)(JsonDialogImpl);
export const SelectorDialog = withStyles(styles)(SelectorDialogImpl);
export const CustomDialog = withStyles(styles)(CustomDialogImpl);
