import _ from "lodash";
import { EventEmitter } from "events";
import { serverAddress } from "../../constants";
import axios from "axios";

export class HttpError {
  code;
  message;

  constructor(message, code = 500) {
    this.code = code;
    this.message = message || "internal error";
  }
}

class HttpClient extends EventEmitter {
  isHttpError;

  _assignId;
  _axiosConfig;
  _httpClient;

  _opts;

  constructor(axiosOpts, opts = {}) {
    super();

    this._axiosConfig = axiosOpts;
    this._httpClient = axios.create(this._axiosConfig);
    this.setOptions(opts);
    this._httpClient.interceptors.response.use(
      response => {
        return response;
      },
      error => {
        if (error.response?.status === 401) {
          window.location.hash = '#/login'
        }
        return Promise.reject(error);
      }
    )
  }

  setOptions(opts) {
    this._opts = opts;
    if (!_.isSafeInteger(this._opts.maxRetry)) {
      this._opts.maxRetry = 10;
    }
    if (!_.isSafeInteger(this._opts.retryIntervalMs)) {
      this._opts.retryIntervalMs = 1000;
    }
    this._assignId = this._opts.assignId;
  }

  /**
   * If the httpLOg is an error returns `code` and `message.
   *
   * @param httpLog
   */
  getHttpReturn(httpLog) {
    if (httpLog.status !== 200) {
      // if (httpLog.data) {
      //   return httpLog.data;
      // }
      return Promise.reject(new HttpError(
        httpLog.statusText || httpLog.errorMessage,
        httpLog.status || (httpLog.errorName && 500)
      ));
    }
    return httpLog.data || {};
  }

  async get(url, config) {
    const httpLog = await this.$get(url, config);
    return this.getHttpReturn(httpLog);
  }

  async post(url, body, config) {
    const httpLog = await this.$post(url, body, config);
    return this.getHttpReturn(httpLog);
  }

  async delete(url, body, config) {
    const httpLog = await this.$delete(url, body, config);
    return this.getHttpReturn(httpLog);
  }

  async put(url, body, config) {
    const httpLog = await this.$put(url, body, config);
    return this.getHttpReturn(httpLog);
  }

  $get(url, config) {
    return this.request(Object.assign(config || {}, { method: "GET", url }));
  }

  $delete(url, config) {
    return this.request(Object.assign(config || {}, { method: "DELETE", url }));
  }

  $post(url, body, config) {
    return this.request(Object.assign(config || {}, { method: "POST", url, data: body }));
  }

  $put(url, body, config) {
    return this.request(Object.assign(config || {}, { method: "PUT", url, data: body }));
  }

  /**
   * Wraps the request and returns [[HttpLog]]
   *
   * @param config
   */
  async request(config, retry = 0) {
    return new Promise(async resolve => {
      const { url, method, data } = config;
      let httpLog = {
        url: url,
        method: method,
        baseURL: this._axiosConfig.baseURL,
        postBody: data
      };
      if (this._assignId) {
        httpLog.uuid = this._assignId(httpLog);
      }
      // debug("http request %o", config);
      let resp;
      try {
        httpLog.requestAt = Date.now();
        resp = await this._httpClient.request(config);
        httpLog.responseAt = Date.now();
        httpLog.elapsedTimeMS = httpLog.responseAt - httpLog.requestAt;
        httpLog.status = resp.status;
        httpLog.statusText = resp.statusText;
        httpLog.data = resp.data;
        this.emit("ok", httpLog, config, this);
      } catch (err) {
        httpLog.isClientError = err.isAxiosError;
        httpLog.responseAt = Date.now();
        httpLog.elapsedTimeMS = httpLog.responseAt - httpLog.requestAt;
        if (err.response) {
          httpLog.status = err.response.status;
          httpLog.statusText = err.response.statusText;
          httpLog.data = err.response.data;
        } else {
          httpLog.status = 500;
          httpLog.statusText = err.message;
          httpLog.errorName = err.name;
          httpLog.errorMessage = err.message;
        }
        this.emit("fail", err, httpLog, config, this);
      } finally {
        const opts = this._opts;
        if (opts.logFn) {
          try {
            const maybePromise = opts.logFn(httpLog);
            if (maybePromise && maybePromise.then) {
              maybePromise.then(promiseReturn => {
                this.emit("log-ok", httpLog, this, promiseReturn);
              });
            } else {
              this.emit("log-ok", httpLog, this);
            }
          } catch (err) {
            this.emit("log-fail", err, httpLog, this);
          }
        }
        const retryFn = config.retryFn ?? opts.retryFn;
        if (++retry <= opts.maxRetry && retryFn) {
          try {
            httpLog.retry = retry;
            const interval = retryFn(config, httpLog, retry);
            if (interval > 0) {
              const timer = setTimeout(() => {
                this.emit("retry", httpLog, config, retry, timer, this);
                this.request(config, retry).then(resolve);
              }, interval);
              return;
            }
          } catch (err) {
            this.emit("retry-error", err, httpLog, config, retry, this);
          }
        }
      }
      resolve(httpLog);
    });
  }
}

const headers = {
  "x-taxi-api": "nrBGGi8VrAa1a9R-JAMZSXaPsTB2oZCB0sCl16xxhue2kR-PwDwPQSYk8qyRrYY5"
};

export default new HttpClient({ baseURL: serverAddress, headers }) 