import React from "react";
import { oauthAddress } from "../constants"

var UserStateContext = React.createContext();
var UserDispatchContext = React.createContext();

function userReducer(state, action) {
    switch (action.type) {
        case "LOGIN_SUCCESS":
            return { ...state, isAuthenticated: true, user: action.user, codeUsed: true };
        case "SIGN_OUT_SUCCESS":
            return { ...state, user: undefined, isAuthenticated: false, codeUsed: false };
        case "LOGIN_FAILURE":
            return { ...state, isAuthenticated: false, codeUsed: true };
        default: {
            throw new Error(`Unhandled action type: ${action.type}`);
        }
    }
}

function UserProvider({ children }) {
    const [state, dispatch] = React.useReducer(userReducer, { isAuthenticated: false, user: undefined });

    return (
        <UserStateContext.Provider value={state}>
            <UserDispatchContext.Provider value={dispatch}>{children}</UserDispatchContext.Provider>
        </UserStateContext.Provider>
    );
}

function useUserState() {
    var context = React.useContext(UserStateContext);
    if (context === undefined) {
        throw new Error("useUserState must be used within a UserProvider");
    }
    return context;
}

function useUserDispatch() {
    var context = React.useContext(UserDispatchContext);
    if (context === undefined) {
        throw new Error("useUserDispatch must be used within a UserProvider");
    }
    return context;
}

export { UserProvider, useUserState, useUserDispatch, signOut };

// ###########################################################

function signOut(dispatch, history) {
    const iframe = document.createElement("iframe")
    iframe.src = `${oauthAddress}/sign_out?rd=https://autox.onelogin.com/oidc/2/logout`
    iframe.style.display = "none"
    iframe.onload = () => {
        dispatch({ type: "SIGN_OUT_SUCCESS" });
        window.location.hash = '#/login'
        document.body.removeChild(iframe)
    }
    document.body.appendChild(iframe)
}
