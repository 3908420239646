import React, { useState } from "react";
import { Grid } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import { oauthAddress } from "../../constants"
import { Button } from "antd";

// styles
import useStyles from "./styles";

const autoxLogo = "/images/logo.png";

function Login(props) {
    const classes = useStyles();
    const [loading, setLoading] = useState(false)

    return (
        <Grid container className={classes.container}>
            <div className={classes.logotypeContainer}>
                <img src={autoxLogo} alt="logo" className={classes.logotypeImage} />
            </div>
            <div className={classes.formContainer}>
                <div className={classes.form}>
                    <Button loading={loading} type="primary" size="large" onClick={() => {
                        setLoading(true)
                        // console.log(`${oauthAddress}/start?rd=${window.location.origin}`)
                        window.location.href = `${oauthAddress}/start?rd=${window.location.origin}`
                    }}>
                        login
                    </Button>
                </div>
            </div>
        </Grid>
    );
}

export default withRouter(Login);
